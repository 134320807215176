/*!
 * Bootstrap v4.0.0-beta (https://getbootstrap.com)
 * Copyright 2011-2017 The Bootstrap Authors
 * Copyright 2011-2017 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

// Core functions, variables and mixins
@import "bootstrap/functions";
@import "espresso/functions";

@import "espresso/variables";
@import "bootstrap/variables";
@import "custom/variables";

@import "bootstrap/mixins";
@import "espresso/mixins";
@import "custom/mixins";

// Reset and dependencies
//@import "root";
//@import "bootstrap/print";
@import "bootstrap/reboot";

// Core CSS
// @import "bootstrap/type";
@import "espresso/type";
@import "bootstrap/images";
//@import "bootstrap/code";
@import "bootstrap/grid";
@import "espresso/grid";
@import "bootstrap/tables";
@import "espresso/tables";
@import "bootstrap/forms";
@import "espresso/forms";
// @import "bootstrap/buttons";
@import "espresso/buttons";

// Transitions and animations
@import "bootstrap/transitions";
@import "espresso/transitions";

// Components
@import "bootstrap/dropdown";
@import "espresso/dropdown";
//@import "bootstrap/button-group";
//@import "bootstrap/input-group";
//@import "espresso/input-group";
@import "bootstrap/custom-forms";
@import "espresso/custom-forms";
@import "bootstrap/nav";
@import "espresso/nav";
//@import "bootstrap/navbar";
@import "bootstrap/card";
@import "espresso/card";
//@import "bootstrap/breadcrumb";
@import "espresso/breadcrumb";
@import "bootstrap/pagination";
@import "espresso/pagination";
@import "bootstrap/badge";
@import "espresso/badge";
//@import "bootstrap/jumbotron";
@import "bootstrap/alert";
//@import "espresso/alert";
//@import "bootstrap/progress";
//@import "bootstrap/media";
@import "bootstrap/list-group";
@import "bootstrap/close";
@import "espresso/close";

// Components w/ JavaScript
@import "bootstrap/modal";
@import "espresso/modal";
//@import "bootstrap/tooltip";
//@import "bootstrap/popover";
@import "bootstrap/carousel";
@import "espresso/carousel";
@import "espresso/collapse";

// Utility classes
@import "bootstrap/utilities";
@import "espresso/utilities";

// Vendor plugins
//@import "vendor/custom";
@import "espresso/material-icons";

// Espresso CMS
// @import "espresso/e-page";
@import "espresso/e-canvas";
// @import "espresso/e-logo";
@import "espresso/e-header";
@import "espresso/e-header-hamburger";
@import "espresso/e-content";
@import "espresso/e-section";
@import "espresso/e-footer";
@import "espresso/e-cta";
@import "espresso/e-nav-global";
@import "espresso/e-nav-utilities-primary";
@import "espresso/e-nav-utilities-secondary";
@import "espresso/e-nav-hamburger-header";
@import "espresso/e-nav-hamburger-aside";
@import "espresso/e-nav-item-btn";
@import "espresso/e-nav-item-search";
@import "espresso/e-nav-item-hamburger-open";
@import "espresso/e-nav-item-hamburger-close";
@import "espresso/e-nav-local";

@import "espresso/e-paragraph";
@import "espresso/e-paragraph-imagetext";
@import "espresso/e-paragraph-icontext";
@import "espresso/e-paragraph-banner";
@import "espresso/e-paragraph-mosaic";
@import "espresso/e-paragraph-section";
@import "espresso/e-paragraph-video";
@import "espresso/e-paragraph-divider";
@import "espresso/e-paragraph-carousel";
@import "espresso/e-paragraph-image-gallery";
@import "espresso/e-paragraph-contact";
@import "espresso/e-paragraph-card";
@import "espresso/e-paragraph-tile";
@import "espresso/e-paragraph-accordion";

@import "espresso/e-search";
@import "espresso/e-nav-item-search";
@import "espresso/e-news";
@import "espresso/e-dealersearch";
@import "espresso/e-scroll-reveal";
@import "espresso/e-back-to-top";
@import "espresso/e-cookie-warning";
@import "espresso/e-parallax";
@import "espresso/e-animate-text";
@import "espresso/e-animate-number";
@import "espresso/e-circle";

// Espresso eCommerce
@import "espresso/e-nav-item-cart";
@import "espresso/ecom-cart";
@import "espresso/ecom-cart-msg";
@import "espresso/ecom-checkout";
@import "espresso/ecom-products-grid";
@import "espresso/ecom-productlist-filters";
@import "espresso/ecom-product-details";
@import "espresso/ecom-download";
@import "espresso/e-costumercenter";

// Custom styling
@import "custom/custom";
