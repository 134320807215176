@mixin badge-variant($bg, $color: color-yiq($bg, $yiq-headings-light, $yiq-headings-dark)) {
  color: $color;
  background-color: $bg;

  &[href] {
    @include hover-focus {
      color: $color;
      text-decoration: none;
      background-color: darken($bg, 10%);
    }
  }
}
