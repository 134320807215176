.p-divider {
    margin-bottom: $paragraph-margin-bottom;
    hr {
        margin: 0;
    }
}

.p-divider-thin hr {
    border-top-width: $border-width;
}

.p-divider-thick hr {
    border-top-width: $border-width-thick;
}

.p-divider-double hr {}
