.p-txt-animate { // TODO: rename class to e-animate-txt
    h1,
    h2,
    h3,
    h4,
    ul,
    p {
        opacity: 0;
        transform: translateY(1.5em);
    }
    &.is-visible {
        h1,
        h2,
        h3,
        h4,
        ul,
        p {
            opacity: 1;
            transform: translateY(0);
            transition: opacity $transition-fade-speed * 2 $transition-fade-easing, transform $transition-slide-speed * 2 $transition-slide-easing;
            $item-entry-delay: 0.5s;
            @for $item from 1 through 5 {
                &:nth-child(#{$item}) {
                    transition-delay: $item-entry-delay * ( $item - 1);
                }
            }
        }
    }
}
