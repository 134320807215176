div[class^="cookieconsent-optout-"], div[class*=" cookieconsent-optout-"] {
    display: flex !important;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    justify-content: center;
    align-items: center;
    background: $gray-200;
    border: 1px solid $gray-300;
    a {
        justify-content: center;
        align-items: center;
    } 
}