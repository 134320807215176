.p-contact {
    margin-bottom: $paragraph-margin-bottom * 2;
    .p-txt-container {
        .p-txt-wrapper {
            font-size: $font-size-sm;
        }
    }
    .p-contact-name {
        margin-bottom: 0;
    }
    .p-contact-title {
        margin-bottom: $spacer / 4 * 3;
    }
    .p-contact-phone {
        white-space: nowrap;
        margin-bottom: $spacer / 2;
    }
    .p-contact-name + .p-contact-phone {
        margin-top: $spacer / 4 * 3;
    }
    .p-contact-email {
        margin: 0;
        white-space: nowrap;
        .btn {
            margin-top: $spacer / 4 * 3;
        }
    }
    .material-icons {
        font-size: 1.25em;
        vertical-align: -25%;
        width: 1.5em;
        text-align: left;
    }
    .p-img-width-auto {
        max-width: 12.5em;
    }
    .p-img-container {
        .col-pos-center {
            margin-left: auto;
            margin-right: auto;
        }
        .col-pos-right {
            margin-left: auto;
        }
    }
}
