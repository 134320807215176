@include media-breakpoint-down($nav-hamburger-breakpoint) {
    //Container styling. All sizes and flexbox props resides in _e-header.scss
    .e-nav-utilities-primary {
        margin: ($spacer * 1.5) 0;
        border-top: $border-width solid $border-color;
        padding-top: $spacer * 1.5;
        // All levels
        .nav {
            flex-direction: column;
            margin: 0 0 ($spacer * 0.675) ($spacer * 1.25);
        }
        .nav-item {
        }
        .nav-link {
            padding: ($spacer * 0.5) ($spacer * 0);
            color: inherit;
            background: none;
            white-space: normal;
            line-height: $line-height-sm;
        }
        .e-nav-pageicon {
            transform: scale(e-strip-unit($spacer));
            + .e-nav-pagename {
                margin-left: 0.875em;
            }
        }
        .e-nav-pageicon-language {
            display: inline-block;
            height: 1em;
            width: 1em;
            vertical-align: middle;
            border-radius: 50%;
            background-size: cover;
            background-position: 50% 50%;
            background-repeat: no-repeat;
            transform: translateY(-0.125em);
        }
        .e-nav-pagename {
            &.is-hidden {
                + .e-nav-pageicon-dropdown {
                }
            }
        }
        .e-nav-pageicon-dropdown {
            //display: none;
        }
        .dropdown-toggle {
            //Dropdown arrow
            &::after {
                //display: none;
            }
        }
        // Level #1
        > .nav {
            flex-direction: column-reverse;
            margin: 0;
            // Props for animation entry in active modal.
            opacity: 0;
            transform: translateY($spacer * 3);
            transition: all $transition-slide-speed * 2 $transition-slide-easing 0.5s;
            .show & {
                opacity: 1;
                transform: translateY(0);
            }
            > .nav-item {
                > .nav-link {
                    padding: ($spacer * 0.375) 0;
                }
            }

        }

    }
}

@include media-breakpoint-up(breakpoint-next($nav-hamburger-breakpoint)) {
    //Container styling. All sizes and flexbox props resides in _e-header.scss
    .e-nav-utilities-primary {
        font-size: $font-size-sm;
        // All levels
        .nav-link {
            color: inherit;
        }
        .e-nav-pageicon {
            color: $headings-color;
            transform: scale(e-strip-unit($spacer));
            + .e-nav-pagename {
                margin-left: 0.875em;
            }
        }
        .e-nav-pageicon-language {
            display: inline-block;
            height: 1em;
            width: 1em;
            vertical-align: middle;
            border-radius: 50%;
            background-size: cover;
            background-position: 50% 50%;
            background-repeat: no-repeat;
            transform: translateY(-0.0675em);
        }
        .e-nav-pageicon-dropdown {
            //display: none;
        }
        .e-nav-pagename {
            &.is-hidden {
                display: none;
                + .e-nav-pageicon-dropdown {
                    display: none;
                }
            }
        }
        .e-nav-pageicon-dropdown {
        }

        .dropdown-menu {
            font-size: 1em;
            margin-top: -1px;
            min-width: 100%;
            .nav-link {
                padding: $dropdown-item-padding-y $dropdown-item-padding-x;
            }
        }
        // Level #1
        > .nav {
            justify-content: flex-end;
            > .nav-item {
                padding: 0 ($spacer * 0.0625);
                > .nav-link {
                    padding: ($spacer * 0.675) ($spacer * 1);
                    display: flex;
                    align-items: center;
                    .e-nav-pageicon-language,
                    .e-nav-pageicon {
                        + .e-nav-pagename {
                            margin-left: $spacer / 2;
                        }
                    }

                    .e-nav-pagename {

                        &.is-hidden {
                            display: none;
                            + .e-nav-pageicon-dropdown {
                                display: none;
                            }
                        }
                        + .e-nav-pageicon-dropdown {
                            margin-left: $spacer / 4;
                        }
                    }
                    .e-nav-pageicon-dropdown {
                        //margin-right: $spacer / 2;
                    }
                }
            }

        }
    }
}
