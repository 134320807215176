.p-mosaic {
    .p-img {
        background-repeat: no-repeat;
    }
    .p-txt-container {
        padding-top: $spacer * 1.5;
        padding-bottom: $spacer * 1.5;
        padding-right: 10%;
        padding-left: 10%;
        :last-child {
            margin-bottom: 0;
        }
    }
}

.p-mosaic-auto {
    .p-img-container {
        @include media-breakpoint-down(sm) {
            min-height: map-get($section-heights, "md") * 1.5;
        }
    }
}

