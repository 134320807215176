@include media-breakpoint-down($nav-hamburger-breakpoint) {
    //Container styling. All sizes and flexbox props resides in _e-header.scss
    .e-nav-hamburger-aside {
        // All levels
        .nav {
            color: $header-color;
            flex-direction: column;
            margin: 0 0 ($spacer * 0.5) ($spacer * 1.25);
        }
        .nav-item {
        }
        .nav-link {
            display: block;
            padding: ($spacer * 0.5) ($spacer * 0);
            color: rgba($header-color, 0.85);
            background: none;
            white-space: normal;
        }
        .e-nav-pageicon {
            transform: scale(e-strip-unit($spacer));
            margin-right: $spacer * 0.5;
        }
        .e-nav-pageicon-language {
            display: inline-block;
            height: 1em;
            width: 1em;
            vertical-align: middle;
            border-radius: 50%;
            // border: $border-width solid $border-color;
            // box-shadow: $shadow-xs;
            background-size: cover;
            background-position: 50% 50%;
            background-repeat: no-repeat;
            // background-clip: padding-box;
            // background-color: $dropdown-bg;
            transform: translateY(-0.0675em);
        }
        .e-nav-pageicon + .e-nav-pagename,
        .e-nav-pageicon-language + .e-nav-pagename {
            margin-left: 0.875em;
        }
        .e-nav-pageicon-dropdown {
            display: none;
        }
        .dropdown-toggle {
            //Dropdown arrow
            &::after {
                display: none;
            }
        }
        // Level #1
        > .nav {
            flex-direction: column-reverse;
            margin: $spacer ($grid-gutter-width / 2) ($spacer * 5);
            padding: 0 $canvas-padding-sm;
            > .nav-item {
                > .nav-link {
                }
            }
        }
    }
}

@include media-breakpoint-up(breakpoint-next($nav-hamburger-breakpoint)) {
    //Container styling. All sizes and flexbox props resides in _e-header.scss
}
