.e-back-to-top {
    position: fixed;
    bottom: $spacer;
    right: $spacer;
    z-index: $zindex-fixed - 1;
    padding: 1em;
    border-radius: $border-radius;
    color: $back-to-top-color;
    background: rgba($back-to-top-bg, 0.35);
    user-select: none;
    transform: translateY(200%);
    transition: transform $transition-slide-speed $transition-slide-easing;
    will-change: transform;
    .modal-open & {
        display: none;
    }
    &:hover,
    &:focus,
    &:active {
        color: $back-to-top-color;
        background: rgba($back-to-top-bg, 0.85);
    }
    &.is-visible {
        transform: translateY(0);
    }
}
