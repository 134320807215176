.e-product {
    .e-product-container {
        font-size: 0.75em;
        position: relative;
        z-index: 2;
        padding: $spacer;
    }
    .e-product-text-container {
    }
    .e-product-name {
    }
    .e-product-text {
    }
    .e-product-price {
    }
    .e-product-price-before {
        text-decoration: line-through;
    }
    .e-product-form {
    }
    .e-product-image-container {
        margin-bottom: $spacer;
        .carousel {
            height: auto;
        }
        .e-product-image-wrapper {
            position: relative;
            padding-top: percentage(80 / 100);
        }
        .carousel-inner {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }
        .carousel-item {
            min-height: 100%;
            .e-product-image {
                width: auto;
                height: auto;
                max-width: 100%;
                max-height: 100%;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                cursor: zoom-in;
            }
        }
        .carousel-indicators {
            font-size: $carousel-indicator-width * 5;
            position: static;
            margin: ($spacer / 8) ($spacer / -16);
            flex-flow: row;
            flex-wrap: wrap;
            justify-content: center;
            //order: -1;
            li {
                flex: 0 0 auto;
                position: relative;
                margin: 0 ($spacer / 16) ($spacer / 8) ($spacer / 16);
                border: $border-width solid $border-color;
                border-radius: $border-width;
                background-color: rgba(0, 0, 0, 0);
                &.active {
                    border-color: theme-color("primary");
                }
                span {
                    position: absolute;
                    top: 0.0625em;
                    right: 0.0625em;
                    bottom: 0.0625em;
                    left: 0.0625em;
                    background-color: rgba(0, 0, 0, 0);
                    background-size: contain;
                    background-position: 50% 50%;
                    background-repeat: no-repeat;
                }

            }
        }

    }
    .e-product-image-modal {
        &.modal-fullscreen {
            overflow-y: hidden;
            .modal-content {
                min-height: 0;
            }
        }
        .modal-dialog,
        .modal-content,
        .modal-body,
        .carousel {
            height: 100%;
        }
        .modal-body {
            padding: 0;
        }
        .carousel-inner {
            height: 100%;
        }
        .carousel-item {
            min-height: 100%;
            background-size: contain;
            background-position: 50% 50%;
            background-repeat: no-repeat;
        }
        .carousel-control {
            &.left {
                left: 0.5em;
            }
            &.right {
                right: 0.5em;
            }
        }
        .carousel-indicators {
            li {
                //border-color: $border-color;
                background-color: $body-color;
                opacity: $text-muted;
                @include media-breakpoint-up(md) {
                    background: $white;
                    opacity: 1;
                    font-size: 3em;
                    margin: $spacer / 8;
                    border-radius: 0;
                    border: $border-width solid $border-color;
                }
                &.active {
                    border-color: theme-color("primary");
                    background: $white;
                    opacity: 1;
                }
                span {
                    display: none;
                    @include media-breakpoint-up(md) {
                        display: block;
                    }
                    position: absolute;
                    top: 0.0625em;
                    right: 0.0625em;
                    bottom: 0.0625em;
                    left: 0.0625em;
                    background-size: contain;
                    background-position: 50% 50%;
                    background-repeat: no-repeat;
                }
            }
        }
    }
    .e-product-section {
        padding: $spacer 0;
        border-top: $border-width solid $border-color;
    }
    .e-product-section-header {
        padding-bottom: $spacer;
        &[data-toggle="collapse"] {
            cursor: pointer;
        }
    }
}
