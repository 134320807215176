.e-news {
    &.has-filter {
        .e-news-item {
            opacity: 1;
            transform: none;
            transition: transform $transition-slide-speed $transition-slide-easing, opacity $transition-fade-speed / 2 $transition-fade-easing;
            will-change: transform, opacity;
        }
    }
    &.is-filtering {
        .e-news-item {
            opacity: 0;
            transform: translateY(50%) scale(.375 .375) rotate(0deg);
            transition: transform $transition-slide-speed $transition-slide-easing, opacity $transition-fade-speed / 2 $transition-fade-easing;
        }
    }
}

.e-news-filter {
    .nav-link {
        padding-right: 2em;
        padding-left: 2em;
        &:not([data-filter="*"]) {
            padding-right: 1em;
        }
        .material-icons {
            width: 1em;
            transform: rotate(45deg);
            opacity: 0;
        }
        &.active {
            .material-icons {
                opacity: 1;
            }
        }
    }
}
