.p-imagetext {
    &[class*="mb-"] {
        .p-txt-container {
            >:last-child {
                margin-bottom: 0;
            }
        }
    }
    .p-img-container {
        .col-pos-center {
            margin-left: auto;
            margin-right: auto;
        }
        .col-pos-right {
            margin-left: auto;
        }
        .p-img[src$="svg"] {
            width: 100%;
        }
    }
}

.p-imagetext-lr {
    &[class*="mb-"] {
        .p-img-container {
            >:last-child {
                margin-bottom: 0;
            }
        }
    }
}

@include media-breakpoint-up(md) {
    .e-section > .container-fluid > .row {
        >.col-12.col-lg-8,
        >.col-12.col-lg-10,
        >.col-12.col-lg-12 {
            .p-imagetext-tb {
                &.col-xl-10,
                &.col-xl-12,
                &.p-width-auto {
                    .p-txt-container {
                        h1[style*="text-align"] {
                            padding-left: 10%;
                            padding-right: 10%;
                        }
                        h2[style*="text-align"] {
                            padding-left: 15%;
                            padding-right: 15%;
                        }
                    }
                }
            }
        }
    }
}
