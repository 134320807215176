//
// Base styles
//
.card {
    @if $enable-shadows {
        box-shadow: $card-shadow;
    }
}

//
// Optional textual caps
//

.card-header {
    padding-top: $card-spacer-y * 1.125;
}

.card-title {
    margin-bottom: 0;
}
