.e-content {
    position: relative;
    background: $body-bg;
    padding-top: $spacer * 3;
    padding-bottom: $spacer * 3;
    @for $size from 1 through 5 {
        &.pt-#{$size} {
            padding-top: ( $spacer * $size * 2) !important;
        }
        &.pb-#{$size} {
            padding-bottom: ( $spacer * $size * 2) !important;
        }
    }
}

