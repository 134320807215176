// Embedded icons from Open Iconic.
// Released under MIT and copyright 2014 Waybury.
// https://useiconic.com/open
// Checkboxes and radios
//
// Base class takes care of all the key behavioral aspects.
.custom-control {
    min-height: (1em * $line-height-sm);
    line-height: $line-height-sm;
    display: flex;
    align-items: center;
}

// Custom indicator
//
// Generates a shadow element to create our makeshift checkbox/radio background.
.custom-control-indicator {
    top: (($line-height-sm - $custom-control-indicator-size) / 2);
    transition: background-color $transition-fade-speed $transition-fade-easing;
}

// Checkboxes
//
// Tweak just a few things for checkboxes.
.custom-checkbox {
    cursor: pointer;
}

// Radios
//
// Tweak just a few things for radios.
.custom-radio {
    cursor: pointer;

}
