@include media-breakpoint-down($nav-hamburger-breakpoint) {
    .e-nav-item-btn {
        .btn {

        }

    }
}

@include media-breakpoint-up(breakpoint-next($nav-hamburger-breakpoint)) {
    .e-nav-item-btn {
        .btn {
            margin: 0 ($spacer / 2);
            font-size: $font-size-sm;
            padding: ($spacer / 2.25) $spacer;
            align-self: center;
        }
        &:last-child {
            .btn {
                margin-right: 0;
            }
        }

    }
}

