.e-products-download {
    .e-products-item {
        .e-product-form-addtocart {
            display: block;
        }
        .e-product-form-removefromcart {
            display: none;
        }
        &.is-in-cart {
            .e-product-form-addtocart {
                display: none;
            }
            .e-product-form-removefromcart {
                display: block;
            }
        }
    }
}
