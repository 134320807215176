.p-image-gallery {
    height: auto;
    margin-bottom: $paragraph-margin-bottom;
    @include media-breakpoint-down(md) {
        .image-gallery-inner {
            // p-image-gallery
            box-sizing: content-box;
            background: $image-gallery-bg;
        }
        .p-image-gallery-item {
            &::before {
                background: none;
            }
        }
    }
    .image-gallery-control-prev,
    .image-gallery-control-next {
        width: $carousel-control-width;
        opacity: .375;
        transition: opacity ($transition-fade-speed * 1.5) $transition-fade-easing;
        color: $image-gallery-controls-color;
        &:hover {
            opacity: .875;
        }
        .image-gallery-control-icon {
            background: $image-gallery-controls-bg;
            box-shadow: $shadow-sm;
        }
        i {
            font-size: 1.25em;
            border-radius: 100%;
        }
    }
    .image-gallery-control-prev {
        justify-content: flex-start;
        i {
            margin-left: $spacer / 3;
        }
    }
    .image-gallery-control-next {
        justify-content: flex-end;
        i {
            margin-right: $spacer / 3;
        }
    }
}

.p-image-gallery-item {
    background-size: cover;
    background-position: 50% 50%;
    height: 100%;
    max-height: 100%;
    min-height: auto;
    .p-image-gallery-ratio-4-3 & {
        padding-top: 75%;
    }
    .p-image-gallery-ratio-1-1 & {
        padding-top: 100%;
    }
    .p-image-gallery-ratio-16-9 & {
        padding-top: 56.25%;
    }
}
