// Fluid container
//
// Utilizes the mixin meant for fixed width containers, but with 100% width for
// fluid, full width layouts.

.container-fluid {
    &.fixed-gutters {
        padding-left: $grid-gutter-width-fixed / 2;
        padding-right: $grid-gutter-width-fixed / 2;
        // Row
        //
        // Rows contain and clear the floats of your columns.
        .row {
            &:not(.no-gutters) {
                margin-left: $grid-gutter-width-fixed / -2;
                margin-right: $grid-gutter-width-fixed / -2;
                > .col,
                > [class*="col-"] {
                    padding-right: $grid-gutter-width-fixed / 2 !important;
                    padding-left: $grid-gutter-width-fixed / 2 !important;
                }
            }
        }
    }
}
