// stylelint-disable selector-no-qualifying-type
//
// Base styles
//
.btn {
    font-family: $btn-font-family; // e4
    cursor: pointer; // e4
    display: inline-block;
    font-weight: $btn-font-weight;
    text-align: center;
    // white-space: nowrap; // e4
    vertical-align: middle;
    user-select: none;
    border: $btn-border-width solid transparent;
    text-decoration: none; // e4
    @include button-size($btn-padding-y, $btn-padding-x, $btn-font-size, $btn-line-height, $btn-border-radius); // e4
    @include transition($btn-transition);
    // Share hover and focus styles
    @include hover-focus {
        text-decoration: none;
        transition: none; // e4
    }
    &:focus,
    &.focus {
        outline: 0;
        box-shadow: $input-btn-focus-box-shadow;
    }
    // Disabled comes first so active can properly restyle
    &.disabled,
    &:disabled {
        opacity: .65;
        @include box-shadow(none);
    }
    &:not([disabled]):not(.disabled):active,
    &:not([disabled]):not(.disabled).active {
        background-image: none;
        @include box-shadow($input-btn-focus-box-shadow, $btn-active-box-shadow);
    }
}

// Future-proof disabling of clicks on `<a>` elements
a.btn.disabled,
fieldset[disabled] a.btn {
    pointer-events: none;
}

//
// Alternate buttons
//
@each $color, $value in $btn-colors {
    .btn-#{$color} {
        @include button-variant(map-get($value, "bg"), map-get($value, "border"), map-get($value, "color"), map-get($value, "hover-bg"), map-get($value, "hover-border"), map-get($value, "hover-color")); // e4
    }
    //.btn-outline-#{$color} {
    //    @include button-outline-variant(map-get($value, "bg"), map-get($value, "color"));
    //}
}

/*.btn-tertiary {
    text-transform: uppercase;
    letter-spacing: 0.0625em;
    font-weight: $font-weight-normal;
}*/

//
// Link buttons
//
// Make a button look and behave like a link
.btn-link {
    font-weight: inherit;
    text-transform: uppercase;
    color: $link-color;
    background-color: transparent;
    @include hover {
        color: $link-hover-color;
        //text-decoration: $link-hover-decoration;
        background-color: transparent;
        border-color: transparent;
    }
    &:focus,
    &.focus {
        border-color: transparent;
        box-shadow: none;
    }
    &:disabled,
    &.disabled {
        color: $btn-link-disabled-color;
    }
    // No need for an active state here
}

//
// Button Sizes
//
.btn-lg {
    @include button-size($btn-padding-y-lg, $btn-padding-x-lg, $btn-font-size-lg, $btn-line-height, $btn-border-radius-lg); // e4
}

.btn-sm {
    @include button-size($btn-padding-y-sm, $btn-padding-x-sm, $btn-font-size-sm, $btn-line-height, $btn-border-radius-sm); // e4
}

//
// Block button
//
.btn-block {
    display: block;
    width: 100%;
    // Vertically space out multiple block buttons
    + .btn-block {
        margin-top: $btn-block-spacing-y;
    }
}

// Specificity overrides
input[type="submit"],
input[type="reset"],
input[type="button"] {
    &.btn-block {
        width: 100%;
    }
}
